
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home_page{
  display: flex;  
  background-color:white;
  height: 100vh;
  flex-direction:column;
  flex-wrap:nowrap;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.log_page{
  height: auto;
}

.home_head{
  height: 3.8rem;
  background-color: rgb(29, 29, 29);
  width: 100%;
  border-bottom: 1px solid rgb(41, 41, 41);

}
.home_contex{
  background-color: rgb(36, 36, 36);
  height:100%;
  width: 100%;
}
.btn_tip{
  border: 1px solid rgb(19, 19, 19);
  position:absolute;
  width: 5rem;
  height:2rem;
  line-height: 2rem;
  margin-left: 3.8rem;
  border-radius:0.6rem;
  background-color: rgb(22, 22, 22);
  color: rgb(212, 212, 212);
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  display: none;
  box-shadow: 3px 3px 3px rgb(0, 0, 0);
}
.mem_seld{
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  width: 1rem;
  height: 1.8rem;
  position:absolute;
  left: -.75rem;
}
.mem_btn{
  border: 1px solid rgb(65, 65, 65);
  width: 3.2rem;
  height: 3.2rem;
  margin-top: 20px;
  box-shadow: 3px 3px 3px rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: 100% 100%;
}



@keyframes out-change-border {  
  0% {  
    border-radius: 35%;  
  }  
  100% {  
    border-radius: 50%;
  }  
}
@keyframes in-change-border {  
  0% {  
    border-radius: 50%;  
  }  
  100% {  
    border-radius: 35%;
  }  
}

.mem_btn_in{
  animation:  in-change-border 0.15s linear forwards;
}
.mem_btn_out{
  animation:  out-change-border 0.15s linear forwards;
}
.mem_btn:hover .btn_tip{
  display: block;
}
.main_menu{
  width: 5.5rem;
  background-color: rgb(30,31,34);
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.sec_list_head_title{
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.712);
  line-height: 2rem;
  text-align: left;
  padding-left: 1rem;
  color: rgb(248, 248, 248);
  font-weight: bold;
}
.sec_memu_list_head{
  border-bottom: 1px solid rgb(68, 68, 68);
  height: 8.5rem;
  background-image: url('https://ai.anyidata.com/images/20230707/2023070702283667640702.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.sec_menu_list{
  height: 3rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 1rem;
  font-size: 15px;
  color: rgb(204, 204, 204);
  cursor: pointer;
  margin: 0.3rem .5rem;
  font-size: 15px;
}
.sec_menu_list:hover{
  background-color: rgb(54,55,61);
  border-radius: 8px;
}
.sec_menu_list_seled{
  background-color: rgb(54,55,61);
  border-radius: 8px;
  color: white;
}
.sec_menu{
  background-color: rgb(43,45,49);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.left_side{
  display: flex;
  flex-direction: row;
  width: 20rem;
  float: left;
  height: 100%;
}
.left_side_gpt{
  display: flex;
  flex-direction: row;
  width: 5rem;
  float: left;
  height: 100%;
}
.right_side{
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.message_side{
  width: 100%;
  background-color: rgb(46,48,53);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 40rem;
}
.message_right_side_open{
  background-color: rgb(46,48,53);
  border-left: 1px solid rgb(20, 21, 22);  
  display: flex;
  flex: 0 0 23rem;  
  flex-direction: column;
  overflow: hidden;
}
.message_right_side_close{
  background-color: rgb(46,48,53);
  border-left: 1px solid rgb(20, 21, 22);  
  display: flex;
  flex: 0 0 1.3rem;   
  border-left: 0px;
  overflow: hidden;
}
.user_mobile{
  font-size: 12px;
  color: #dbdf11;
  text-decoration: underline;
}
.right_side_setbtm{
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top:50vh;
  margin-left: -1.25rem;
  background-image: url('/src/res/slid.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;  
}
.set_close{
  transform: rotate(180deg);
}
.model_title{
  width: 6rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(17, 16, 16);
  color: rgb(204, 202, 202);
  font-size: 13px;
  border-bottom: 1px solid rgb(221, 134, 19);
}
.model_side{  
  height: 100%;
  overflow-y: scroll;
  border-top: 1px solid rgb(36, 32, 32);  
}
.model_side::-webkit-scrollbar{
  width: 5px;
}
.model_img_box{
  margin:0 auto;
  width: 100%;
  height: 3rem;
}

.model_img_box img{
  width: 100%;
}
.model_img_list
{
  margin-top: 2rem;
  height:3rem;
  background-color: rgb(54, 56, 56);
  display: flex;
  justify-content:space-around;
  align-items: center;
  margin-bottom: 2rem;
}
.model_img_list img{
  height:5rem;
  border-radius:.5rem;
  border: 1px solid rgb(37, 37, 37);
  box-shadow: 0px 0px 15px rgba(14, 13, 13, 0.836);
  cursor: pointer;
}
.model_img_list img:hover{
  box-shadow: 0px 0px 15px rgba(167, 167, 167, 0.836);
}
.model_prompt{
  width: 95%;
  text-align: left;
  margin:.5rem auto;
  height: 6.5rem;
  color:rgb(167, 167, 167);
}
.model_prompt div{
  width: 100%;
  height: 5rem;
  background-color: rgb(56,58,64);
  border: 1px solid rgb(34, 33, 33);
  border-radius: 8px;
  overflow-y: scroll;
  font-size: 13px;
  padding: 3px;
}
.model_prompt div::-webkit-scrollbar{
  width: 0px;
}
.model_prompt span{
  margin: 0 .3rem;
}
.model_set{
  height: 2rem;
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px .5rem;
  margin: 0 .5rem;
  font-size:12px;
  float: left;
  color:rgb(167, 167, 167);
  
}
.model_set div{
  flex: 1;
  line-height: 1.5rem;
  height: 1.5rem;
}
.long_set{
  width:94%;
}
.long_set div{
  flex:auto;
}
.long_set div:nth-child(1){
  flex: 0 0 4.5rem;
}
.model_set div:nth-child(1)
{
  text-align: left;
}
.model_set div:nth-child(2)
{
  text-align: right;
  background-color: rgb(56,58,64);
  padding-right: .5rem;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow: hidden;
  
}
.message_box{
  height: 100%;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width:98%;
  
}
.message_box::-webkit-scrollbar{
  width: 10px;
  color: white;
}
.send_box{
  height: 7.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  float: left;
}
.send_box_min{
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
}
.img2img_input{
  height:100%;
  border-radius: .3rem;  
  border: 1px solid rgb(46, 46, 46);
  position:relative;
}
.input_img_panel{
  width: 4rem;  
  margin: .3rem;
  margin-left: -.6rem;
  overflow: hidden;
}
.remove_panel{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;  
  color: rgb(235, 17, 46);
  margin-top: -5rem;
  background-color: rgba(0, 0, 0, 0.596);
  position: relative;
  backdrop-filter: blur(2px);
}
.input_message{
  width: 100%;
  height: 5.5rem;
  background-color: rgb(56,58,64); 
  border-radius: .6rem;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.input_message_min{
  width: 100%;
  height: 3rem;
  background-color: rgb(56,58,64); 
  border-radius: .6rem;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
textarea{  
  background-color: rgb(56,58,64);
  width: 100%;
  height: 93%;
  border: 0px;
  font-size: 15px;
  color: rgb(223, 223, 223);
  border-radius: .6rem;
  float: left;  
  resize: none;  
  overflow-y:inherit;
}
.sendmessage_btn{
  margin-right: -1rem;
  width: 3.5rem;
  border: 1px solid green;
  background-color: green;
  color: white;
  text-align: center;
  line-height: 2.5rem;
  cursor: pointer;
  border-radius: 0px .6rem .6rem 0px;
}
.sendmessage_btn:hover{
  background-color: rgb(12, 182, 12);
  display: flex;
  justify-content: center;
}
.sendmessage_btn div{
  background-image: url('/src/res/send.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.input_message textarea::-webkit-scrollbar{
  width: 0px;
  height: 0px;
}

.input_btn{
  width: 5rem;
  height:100%;
  float: left;
  background-position: center;
  background-repeat:no-repeat;
  margin-left: 0.5rem;
  cursor:pointer;
  text-align: center;
  font-size: 12px;
  color: rgb(102, 102, 102);
  margin-top: .5rem;
  display: flex;
  flex-direction:column;
  align-items: center;
}
.input_btn:hover{
  background-color: rgba(14, 14, 14, 0.288);
}
.bottom_box{
  background-color: rgb(39, 39, 39);
  display: flex;
  flex-direction: column;
  height: 16rem;
}
.bottom_box_min
{
  background-color: rgb(39, 39, 39);
  display: flex;
  flex-direction: column;
  height: 4rem;
}

.set_box{
  height: 4.3rem;
  border-radius:5px;
  background-color: rgb(39, 39, 39);
}
.up_btn{
  background-image: url('/src/res/plu.png');
}
.up_btn_mini{
  background-image: url('/src/res/add.png');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 3rem;
  height: 2rem;
}
.lora_btn{
  background-image: url('/src/res/model.png');
}
.model_img{
  width: 3rem;
  height: 3.3rem;
  border-radius: .5rem;
  border: 1px solid rgb(94, 93, 93);
}
.resize_btn{
  background-image: url('/src/res/real-size.png');
}
.samp_btn{
  background-image: url('/src/res/samp_med.png');
}
.setup_btn{
  background-image: url('/src/res/setp.png');
}
.vae_btn{
  background-image: url('/src/res/vae.png');
}
.cfg_btn{
  background-image: url('/src/res/cfg.png');
}
.drawde_btn{
  background-image: url('/src/res/drawde.png');
}
.zoom_btn{
  background-image: url('/src/res/zoom.png');
}
.seed_btn{
  background-image: url('/src/res/seed.png');
}
.clip_btn{
  background-image: url('/src/res/clip.png');
}
.message_line{
  width: 99%;
  height: auto;
  flex: 0 0 auto;
  margin-top: 1rem;
  flex-direction:column;
  padding-left: 4rem;
}
.message_head{
  width: 100;
  display: flex;
  flex-direction: row;
  line-height: 2.8rem;
  margin-left: -4rem;
}
.user_message_icon{
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: center;
  box-shadow: 5px 5px 5px rgb(26, 26, 26);
  overflow: hidden;
  cursor: pointer;
}
.user_message_icon img{
  width: 100%;
  height: 100%;  

}
.message_master{
  color:rgb(26, 182, 96);
  margin-left: 1rem;
  font-size: 16px;
}
.message_user{
  color: rgb(107, 150, 172);
  margin-left: 1rem;
  font-size: 16px;
}
.message_time{
  color: rgb(112, 113, 114);
  font-size: 12px;
  margin-left: 1rem;
}
.message_title{
  color: rgb(209, 209, 209);
  margin-bottom: .5rem;
}
.processimg{
  width: 35rem !important;
  cursor:auto !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.432);
  margin-left: 2px;
}
.moreimg{
  width: 11.5rem !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.432);
  margin-left: 2px;
  cursor: pointer !important;
}
.moreimg:hover{
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  border-radius: 15px;
}
.one_moreimg{
  width: 20.5rem !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.432);
  margin-left: 2px;
  cursor: pointer !important;
}
.one_moreimg:hover{
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  border-radius: 15px;
}
.pro_img{
  height: 1.9rem;
  width: 11.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  position:relative;
  margin-bottom: -1.9rem;
  background-color: rgba(0, 0, 0, 0.473);
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0.1px solid rgb(70, 70, 70);
  text-align: center;  
}
.pro_img_2{
  left: 11.7rem;
}
.pro_img_3{
  left: 23.4rem;
}
.pro_img div{
  width: 50%;
  text-align: center;
  cursor: pointer;
}
.pro_img_only_one div{
  width: 100% !important;
}
.pro_img div:nth-child(2)
{
  border-left: 1px solid rgb(97, 97, 97);
}
.by_user{
  color: rgb(96, 125, 139);
  cursor: pointer;  
  margin-left: .5rem;
  
}
.by_wait{
  color: rgb(219, 173, 22);
  cursor: pointer;  
  margin-left: .5rem;
  
}
.by_text{
  color: rgb(255, 255, 255);
  margin-left: .5rem;  
}
.prompt_text{
  color: rgb(20, 216, 20);
}
.neprompt_text{
  color: rgb(231, 182, 22);
}
.flex_div{
  display: flex;
  overflow: hidden;
}
.set_panel{
  border: 1px solid rgb(68, 68, 68);
  background-color: rgb(56,58,64);
  height: 30rem;
  width: 95%;
  margin: 0 auto;
  border-radius: .5rem;
  display: flex;
  flex-direction: row;
  width: 47rem;
}
.data_panel{
  border: 1px solid rgb(68, 68, 68);
  background-color: rgb(56,58,64);
  height: 30rem;
  width: 95%;
  margin: 0 auto;
  border-radius: .5rem;
  display: flex;
  width: 47rem;
  box-shadow:5px 5px 5px rgba(0, 0, 0, 0.356);
  overflow-y: scroll;
}
.flex_row{flex-direction: row;}
.flex_col{flex-direction: column;}
.samp_box{
  cursor: pointer;
  flex: 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.samp_box:hover{
  background-color: rgba(0, 0, 0, 0.651);
}
.showset{
  color: rgb(170, 165, 159);
  width: 100%;
  margin-top: 2.2rem;
  font-size: 12px;
  height: 1.2rem;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
}
.slider_box{
  margin: auto;
  width: 80%;
  height: 1rem;
}
.med_hint{
  color: rgb(151, 151, 151);
  width: 25rem;
  margin: auto;
  text-align: center;
}
.med_conbtn{
  width: 7rem;
  height: 2rem;
  background-color: rgb(26, 26, 26);
  text-align: center;
  line-height: 2rem;
  border-radius: .5rem;
  margin: auto;
  cursor: pointer;
}


.set_contex_panel{
  height: 2rem;
  width:100%;
  height: 100%;
  overflow-y: scroll;
}
.model_list{
  width: 11rem;
  height: 12rem;
  border: 1px solid rgb(58, 55, 55);
  margin: .2rem .2rem;
  float: left;
  overflow: hidden;
  cursor: pointer;
  border-radius: 5px;
}
.model_list:hover{
  box-shadow:0px 0px 5px rgb(97, 95, 95);
  border: 1px solid rgb(88, 86, 86);
}
.model_list img{
  width: 100%;
  height: auto;
  margin-top: -1.5rem;
}
.model_name{
  position:relative;
  font-size: 14px;
  text-align: left;
  background-color: rgba(27, 27, 27, 0.767);
  height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;  
  color: rgb(255, 255, 255);
  text-align: center;
  display: flex;
  flex-direction: row;
}
.size_box{
  width: 20%;
  height: 100%;
}
.resize_div{
  width: 80%;
  height: 8rem;
  margin: 0 auto;
  margin-top: 3rem;
  display:flex;
  align-items: center;
  justify-content: center;  
}
.resize_div div{
  margin: 0 auto;
  border: 1px solid rgb(138, 138, 138);
  border-radius: 5px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.527);
}
.resize_div div:hover{
  background-color: rgb(27, 27, 27);
}
.resize_1{
  width: 100%;
  height: 100%;  
}
.resize_2{  
  width: 100%;
  height: 75%;  
}
.resize_3{
  width: 75%;
  height: 100%;  
}
.resize_4{
  width: 100%;
  height: 57%;  
}
.resize_5{
  width: 57%;
  height: 100%;  
}
.cu_sd_setting{
  width: 99%;
  /* margin: 0 1rem; */
  font-size: 14px;
  color: rgb(92, 92, 92);
  border-bottom: 1px solid rgb(92, 92, 92);

}
.nodisplay{
  display: none;
}
.handleimg_box{
  /* border: 1px solid blue; */
  width: 60%;
  height: 90%;
  position:fixed;
  display: flex;
  align-items:center;
}
.img2img_box{
  border: 1px solid rgb(22, 22, 22);
  margin: 0 auto;
  width: 35rem;
  height: 10rem;
  border-radius: 0.5rem;
  background-color: rgb(30, 31, 32);
  box-shadow: 5px 5px 5px rgba(2, 2, 2, 0.342);
  display: flex;
  flex-direction: column;
  color: rgb(161, 161, 161);
  font-size: 15px;
}
.img2img_box textarea{
  border-radius: 5px;
  width: 96%;
  margin-left: .5rem;
  height: 4.5rem;
}
.img2img_title{
  height: 2rem;
  line-height: 2rem;
  padding-left: .56rem;
}
.img2img_btnop{
  height: 100%;
}
.img2img_right_btn{
  width: 5rem;
  float: right;
  border: 1px solid rgb(133, 133, 133);
  height: 2rem;
  margin: 0 .5rem;
  border-radius: 5px;
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  color: white;
  cursor: pointer;
}
.img2img_right_btn:nth-child(2){
  background-color: rgb(62, 44, 138);
}

.img2img_right_btn:hover{
  background-color: rgb(49, 49, 49);
}
.donwload_btn{
  font-size: 13px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px rgba(8, 8, 8, 0.479);
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem 0;
  float: left;
  cursor: pointer;
  border: 1px solid rgb(17, 97, 21);
  background-color: rgb(8, 90, 8);
}
.seed_input{
  background-color:  rgb(43,45,49);
  border: 0px;
  margin:0 auto;
  text-align: center;
  height: 2rem;
  border-radius: .5rem;
  font-size: 15px;
}
.seed_title{
  border: 1px solid rgba(107, 107, 107, 0.342);
  color:rgba(107, 107, 107, 0.993);
  width: 3rem;
  font-size: 13px;
  margin-left: -2.95rem;
  margin-top: 1rem;
  margin-bottom: -1.7rem;
  text-align: center;
  height: 1.37rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.seed_panel{
  color: rgb(104, 104, 104);
  width: 35rem;
  height: 2rem;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-around;
}
.seed_panel div{
  border: 1px solid rgba(107, 107, 107, 0.342);
  width: 33.33%;
  text-align: center;
  cursor: pointer;
}
.seed_panel div:hover{
  background-color: #12c712ad;
  color: white;
}
.panel_btn{
  height: 3rem;
  display: flex;
  width: 20rem;
  margin: 0 auto;
}
.model_name_box{
  height: 3rem;
  width: 100%;
  color: rgb(201, 203, 204);
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.back_layer{
  width: 100%;
  position:absolute;
  z-index: 1;
}
.con_layer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.con_layer *{
  z-index: 100 !important;
}
.log_lay1{  
  width: 95%;
  height: 30rem;
  transform: rotate(7.813deg);
  flex-shrink: 0;
  background: linear-gradient(121deg, rgba(124, 254, 246, 0.80) 25.52%, rgba(238, 168, 255, 0.80) 100%);
  filter: blur(100px);
}
.log_lay2{
  position: absolute;
  margin-left: -8rem;
  top: -10rem;
  width: 49.25rem;
  height: 49.25rem;
  flex-shrink: 0;
  border-radius: 788px;
  background: linear-gradient(175deg, #C3F467 0%, rgba(195, 244, 103, 0.00) 100%);
  filter: blur(100px);
}
.log_lay3{
  position: relative;
  margin-left: -3rem;
  top: 20rem;
  width: 62.5625rem;
  height: 49.875rem;
  flex-shrink: 0;
  border-radius: 1001px;
  background: linear-gradient(102deg, rgba(118, 245, 254, 0.568) 0%, rgba(255, 99, 224, 0.301) 54.17%, rgba(192, 133, 255, 0.10) 100%);
  filter: blur(100px);
}
.log_lay4{
  position: relative;
  margin-left: 50rem;
  top: 25rem;
  width: 62.5625rem;
  height: 49.875rem;
  flex-shrink: 0;
  border-radius: 1001px;
  background: linear-gradient(121deg, rgba(124, 254, 246, 0.80) 25.52%, rgba(238, 168, 255, 0.80) 100%);
  filter: blur(100px);
}
.log_head{
  display: flex;
  width: 76.5rem;
  justify-content: space-between;
  align-items: center;
  margin: 3rem auto;
}

.log_btn{
  display: flex;
  width: 7.875rem;
  padding: 0.5rem 0rem 0.5625rem 0rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #000000;
  text-align: center;
  font-size: 1.125rem;
  font-family: Alibaba PuHuiTi;
  border-radius: 8px;
  border: 1px solid #89af3b;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(138deg, #CDFF66 0%, #8EFFFE 100%);
}
.log_btn:hover,.login_btn:hover,.get_rand:hover{
  background: linear-gradient(138deg, #2dca18 0%, #18c096 100%);
}
.log_img{
  width: 7.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  /* background-image: url('/src/res/log.svg'); */
  color: black;
  background-repeat: no-repeat;
  background-position: center;
}
.main_ad{
  width: 70rem;
  height: 22rem;
  background-image: url('/src/res/best_ai.png');
  background-repeat: no-repeat;
  background-position: center;
}
.on_word{
  font-size: 14px;
  height: 3rem;
  color: black;
  z-index: 100;
  font-size: 25px;
  letter-spacing:0.15rem;
}
.start_build{
  width: 15rem;
  height: 4rem;
  background-image: url('/src/res/start_bd.png');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.start_build:hover{
  background-image: url('/src/res/start_bd_sel.png');
}
.home_img_list{
  width: 85rem;  
  /* border: 1px solid red; */
}
.home_img_list img
{
  height:auto;
  border-radius: 2.5rem;
  margin: 1.5rem;
  max-height:25rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.329);
}
.home_img_list img:nth-child(1)
{
  margin-top: -8rem;
}
.home_img_list img:nth-child(3)
{
  margin-top: -8rem;
}
.home_img_list img:nth-child(4)
{
  margin-top: -8rem;
}
.home_img_list img:nth-child(7)
{
  margin-top: -8rem;
}
.build_img{
  height: 45rem;
  width: 80rem;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  /* border: 1px solid red; */
}
.build_img_left
{
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 20rem;
}
.build_img_left span{
  color: #7A7A7A;
  font-size: 1.125rem;
  font-family: Alibaba PuHuiTi;
  margin-top: 2rem;
  width: 90%;

}
.build_img_right
{
  width: 70%;
  overflow: hidden;
}
.build_img_right img{
  width: 60%;
  margin:0 auto;
  margin-left: 10rem;
  border-radius: 3rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.664);
}
.build_title{
  background-image: url('/src/res/ai_build.png');
  width: 100%;
  height: 3rem;
  background-repeat: no-repeat;
}




.build_img_left2
{
  width: 70%;
  overflow: hidden;
  height: 30rem;
}

.build_img_right2
{
  
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 20rem;
}
.build_img_left2 div{
  border-radius: 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.664);
  
}
.build_img_right2 span{
  color: #7A7A7A;
  font-size: 1.125rem;
  font-family: Alibaba PuHuiTi;
  margin-top: 2rem;

}
.aistock_title{
  background-image: url('/src/res/aistock_title.png');
  width: 100%;
  height: 3rem;
  background-repeat: no-repeat;
}
.md1{
  position: absolute;
  width: 21rem;
  height: 15rem;
  background-image: url('/src/res/md1.png');
  background-repeat: no-repeat;
  border-radius: 10px;  
  background-size: 100%;
}
.md2{
  position: absolute;
  width: 23rem;
  height: 31rem;
  background-image: url('/src/res/md2.png');
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-left: 22rem;
  background-size: 100%;
}
.md3{
  position: absolute;
  width: 21rem;
  height: 15rem;
  background-image: url('/src/res/md3.png');
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 16rem;
  background-size: 100%;
}
.page_bottom{
  height: 15rem;
  background-color: rgb(37, 37, 37);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.bottom_contex{
  height: 13rem;
  width: 90rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottom_log
{
  width: 10rem;
  height: 5rem;
}
.log_img_white{
    width: 7.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    /* background-image: url('/src/res/log_white.svg'); */
    background-repeat: no-repeat;
    background-position: center;
  }
  .log_layer{
    position:fixed;
    width: 100%;
    height: 100vh;
    background-color: #000000a8;
    z-index: 10000;
    top: 0;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .log_box{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(146, 208, 233);
    width: 35rem;
    height: 28rem;
    border-radius: 2rem;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.658);
    background-color: white;
    background: linear-gradient(121deg, rgba(124, 254, 246, 0.80) 15.52%, rgba(238, 168, 255, 0.80) 100%);
  }
  .log_close{
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .close_btn{
    height: 3rem;
    width: 5rem;
    background-image: url('/src/res/close-circle-fill.svg');
    background-repeat: no-repeat;
    background-position: center;    
    cursor: pointer;
  }
  
  .log_title{
    height: 4rem;
    color:black;
    font-size: 25px;
    display: flex;
    align-items:center;
    font-weight: bold;
    padding-left: 2rem;
    letter-spacing: .1rem;
  }
  .input_box{
    height: 18rem;
    display: flex;
    flex-direction: column;
  }
  .input_box_inputdiv{
    width: 90%;
    height: 3.5rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    border-radius: .5rem;
    background-color: #eeeeee;
    overflow: hidden;
  }
  .mobile_input{
    width: 100%;
    letter-spacing:.2rem;
    font-size: 15px;
    font-weight: bold;
    border: 0px;
    background-color: #eeeeee;
  }
  .mobile_svg{
    background-image: url('/src/res/mobile.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 100%;
  }
  .ran_svg{
    background-image: url('/src/res/ran.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 100%;
  }
  .get_rand{
    width: 6rem;
    display: flex;
    background: linear-gradient(138deg, #CDFF66 0%, #8EFFFE 100%);
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    cursor: pointer;
    letter-spacing: .5rem;
    font-weight: bold;
  }
.login_btn{
  font-size: 18px;
  font-weight: bold;
  color: black;
  display: flex;
  height: 3.2rem;
  width: 26.25rem;
  padding: 0.625rem 0rem 0.6875rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(138deg, #CDFF66 0%, #8EFFFE 100%);
  box-shadow: 0px 24px 32px 0px rgba(151, 255, 235, 0.20);
  margin: auto;
  cursor: pointer;
}
.btn_panel{
  width: 18rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Trigger_Words_box{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem .5rem;
  color:rgb(167, 167, 167);
  font-size: 13px;
}
.words_panel{
  display: flex;
  flex-wrap: wrap;
}
.Trigger_Words_box_title{
  flex: 0 0 4rem;
}
.Trigger_Words_box span{
  background-color: #6118c0cc;
  margin: .3rem .2rem;
  border-radius: .3rem;
  padding: .2rem;
  color: rgb(199, 197, 66);  
  cursor: pointer;
}
.Trigger_Words_box span:hover{
  background-color: #7f39dbcc;
  color: rgb(240, 237, 75); 
}
.lage_box{
  color:rgb(167, 167, 167);
  display: flex;
  flex-direction: row;
  width: 94%;
  margin: .5rem auto;
}
.lag_box_sel{
  background-color: #363636;
  width: 65%;
}
.user_center{
  width: 100%;
  height: 100%;
  
}
.user_title{
  font-size: 16px;
  border: 1px solid rgb(41, 41, 41);
  height: 3rem;
  line-height: 3rem;
  padding-left: 1rem;
  
}
.my_profile{
  width: 50rem;
  height: 30rem;  
  margin-left: 3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}
.change_avt{
  height: 100%;
  width: 23rem;
  display: flex;
  flex-direction: column;
}
.avt_view{
  width: 26rem;
  height: auto;
}
.norm_str{
  color: #dfdfdf;
  font-size: 15px;
  line-height: 3rem;
}
.hit_str{
  color: #838181;
  font-size: 12px;
  line-height: 3rem;
}
.green_btn{
  background-color: #14702b;
  width: 8rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
  font-size: 12px;
  cursor: pointer;
  margin:1rem 0;
}
.green_btn:hover{
  background-color: #1b9239;
}
.profile_input{
  width: 15rem;
  background-color: #55565a;
  border: 0px;
  height: 2rem;
  border-radius: 3px;
  padding-left: 1rem;
}
.avt_panel{
  border: 1px solid rgb(65, 65, 65);
  height: 12rem;
  border-radius: .5rem;
  overflow:hidden;
  background-color: rgb(236, 236, 236);
  
}
.avt_panel_top{
  background-image: url('/src/res/avt_back.png');
  height: 5.5rem;
  background-size: 100%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.322);
}
.avt_img{
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 2px solid rgb(99, 97, 97);
  position:fixed;
  margin-top: -3.2rem;
  margin-left: 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.322);

}
.avt_nick{
  color: black;
  margin-top: 2rem;
  margin-left: 1rem;
  height: 1.5rem;
  width: 5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow: hidden;
  font-size: 15px;  
}
.my_money{
  color: rgb(196, 91, 30);
  font-size: 45px;
}
.money_box{
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.money_box span{  
  border: 1px solid green;
  height: 2rem;
  width:5.3rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: .3rem;  
}
.money_box span:hover{
  background-color: #14702b;
  color:white;
  cursor: pointer;
}
.wxpay_box{
  width: 45rem;
  height: 25rem;
  border: 1px solid rgb(63, 63, 63);
  position:absolute;
  background-color:rgba(17, 17, 17, 0.74);
  backdrop-filter: blur(5px);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
}
.wx_logo{
  background-image: url('/src/res/wx_logo.svg');
  width: 100%;
  height: 4rem;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(46, 46, 46);
}
.wx_qr_box{
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
.qr_left{
  flex:0 0 20rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(68, 68, 68, 0.199);

}

.qr_right{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pay_money{
  text-align: center;
  color: #26ad21;
  font-size: 20px;
}
.pay_money_title{
  text-align: center;
  color: #757575;
  font-size: 14px;
}
.qrcode{
  width: 12rem;
  height: 12rem;
  padding: .3rem;
  margin: 1rem auto;
  border: 1px solid rgb(75, 75, 75);
  border-radius: .5rem;
}
.fl_right{
  float: right;
}
@keyframes payok_box {  
  0% {  
    height: 0%;
  }  
  100% {  
    height:86%;
  }  
}
.pay_ok{
  color: #26ad21;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  margin: -1rem;
  display: flex;
  backdrop-filter: blur(5px);
  animation:  payok_box 0.15s linear forwards;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ok_img{
  background-image: url('/src/res/success.svg');
  width: 5rem;
  height: 5rem;
  background-repeat: no-repeat;
  background-position: center;

}
.user_home_page{
  width: 100%;
  height: 100%;
  background-color: #000000c7;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  
}
.user_home_page *{
  z-index: 100 !important;
}
.usr_top{
  height: 3rem;
  background-color: #181717ea;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:fixed;
  width: 100vw;
  z-index: 100000;
  top: 0;  
}
.usr_top span{
  display: block;
  width: 5rem;
  text-align: center;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f1610d;
  cursor: pointer;
}
.usr_top span:hover{
  background-color: #272727;
  border-bottom:2px solid rgb(218, 181, 20);
}
.usr_background{
  height: 8rem;
  
}
.usr_box{
  width: 80rem;
  margin: 0 auto;
  height: 18rem;
  display: flex;
  flex-direction: row;
  background-color: #00000034;
  backdrop-filter: blur(2px);
  padding-left: 2rem;
  border-radius: 5rem 1rem 3rem 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.26);
  border: 1px solid rgba(41, 41, 41, 0.151);  
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  
}
.usr_back_img{
  position:absolute;
  width: 100%;
  margin-left: -2rem;
}
.user_back_left{
  width: 50%;
  position: absolute;
  z-index: 100;
  height: 100%;
  margin-left: -2rem;
  background: linear-gradient(to right, rgb(27, 2, 39), rgba(3, 3, 3, 0));
}
.usr_avt{
  margin-top: 3rem;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;  
  border: 3px solid rgb(250, 249, 249);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.651);
}
.usr_info{  
  margin-left: 2rem;
  /* border: 1px solid red; */
  width: 35rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.usr_foc{
  width: 6rem;
  height: 1.8rem;
  background-color: rgb(206, 31, 147);
  color: white;
  text-align: center;
  line-height: 1.8rem;
  border-radius: .2rem;
  cursor: pointer;
  border: 1px solid rgb(122, 19, 88);
  font-size: 12px;
}
.usr_foc:nth-child(2)
{
  margin-left: .3rem;
}
.usr_lin{
  width: 100%;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: row;
  margin: .5rem 0;
}
.usr_name{
  color: white;
  font-size: 25px;
  font-weight: bold;
  /* border: 1px solid red; */
  line-height: 3rem;
  height: 3rem;
}
.xun_box{
  display: flex;
  flex-direction: row;
  /* border: 1px solid green; */
}
.xun{
  width: 2.5rem;
  height: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-left: .8rem;
  
}
.xun1{background-image: url('/src/res/xun1.png');}
.xun2{background-image: url('/src/res/xun2.png');}
.xun3{background-image: url('/src/res/xun3.png');}
.xun4{background-image: url('/src/res/xun4.png');}
.xun5{background-image: url('/src/res/xun5.png');}
.usr_data{
  /* border: 1px solid white; */
  height: 2.5rem;
  line-height: 2.5rem;
  color: rgb(212, 212, 212);
}
.usr_data span{
  color: rgb(235, 231, 12);
}
.usr_label span{
  background-color: #8129bbec;
  margin: 0 .3rem;
  color: rgb(223, 219, 219);
  padding: .5rem;
  border-radius: .3rem;
  font-size: 12px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.541);
}
.usr_ban{
  width: 80%;
  margin: 0 auto;
  flex: 0 0 10rem;
  margin-bottom: 2rem;
  /* border-bottom-style: dotted;
  border-bottom: 1px dashed rgb(87, 90, 89); */
}

.usr_product{
  margin: 0 auto;
  min-height: 60rem;
  text-align: center;
  overflow: hidden;
}
.image_list{
  width: 80rem;
  row-gap: 0.5;
  column-count:4;
  column-gap:0;
}
.image_list div{
  /* border: 1px solid red;   */
}
.image_list div img{
  border-radius: .8rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.521);
  width: 18rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.start_panel{
  border-radius:0 0 .3rem .3rem;
  width: 18rem;
  height: 2rem;
  margin: 0 auto;
  position: relative;
  margin-bottom: 1rem;
  margin-top: -3rem;
  color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.267), rgb(0, 0, 0));
  padding-left: 1rem;
}
.message_start_main{
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:34.5rem ;
  margin-top: -2rem;

}
.message_start_mainone{
  width: 20.6rem !important;
  justify-content: center;
}
.message_start_panel{
  border-radius:0 0 .3rem .3rem;
  width: 33.8%;
  height: 2rem;
  margin: 0 auto;
  color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.267), rgb(0, 0, 0));
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.message_start_panelone{
  width: 100% !important;
}
.no_repeat{
  background-repeat: no-repeat;
  background-size: auto 100%;
  float: left;
  width: 6.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.start_nums{
  height:2rem;
  background-image: url('/src/res/start_img.png');
  
}
.eval_nums{
  align-items: center;
  justify-content: center;
  height:2rem;
  background-image: url('/src/res/eval_img.png');
}
.fullshow_panel{
  position:fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0000008e;
  z-index: 10000;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  margin-top: -3rem;
}
.fullimg_panel{
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  padding-top: 3rem;
}
.full_margin_left{
  margin-left: -20rem;
}
.full_parcent_panel{
  height: 93vh;
}
.fullimg_panel_img{  
  height: 100%;
  /* border-radius: 1rem; */
  /* box-shadow: 5px 5px 5px black; */
}
.gpt_panel{
  border: 0px;
  width: 100%;
  height: 100%;
}
.eval_panel
{
  
  border: 1px solid rgb(39, 39, 39);
  width: 25rem;
  height:90%;
  background-color: #2b2b2bea;
  margin-left: 2rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 5px black;
  position: absolute;
  right: 2rem
}

.eval_head{
  height: 4rem;
  display:flex;
  align-items: center;
  padding-left: .5rem;
  position:absolute;
  background-color: #161616e5;
  width: 25rem;
}
.eval_avt{
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: 1px solid rgb(26, 26, 26);
  box-shadow: 3px 3px 3px black;

}
.eval_nick{
  color: rgb(18, 138, 168);
  font-size: 14px;
  margin-left: .5rem;
}
.eval_time{
  font-size: 13px;
  margin-left: .5rem;
}
.eval_start{
  height: 3rem;
  display: flex;
  align-items: center;  
  color: white;  
  border-bottom: 1px solid rgba(61, 61, 61, 0.658);
}
.eval_heart{
  background-image: url('/src/res/heart-fill.png');
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.eval_eval{
  background-image: url('/src/res/eval_img.png');
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  background-size: 82%;
  background-repeat: no-repeat;
}
.eval_start_nums{
  cursor: pointer;
}
.eval_prompt{
  border: 1px solid red;
  height: auto;
}
.split_panel{
  flex: 0 0 4.5rem;
}
.eval_main_panel{
  display: flex;
  height: 90%;
  flex-direction: column;
}
.eval_mspanel{
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;  
}
.eval_mspanel::-webkit-scrollbar{
  width: 0px !important;
  height: 0px;
}
.close_eval_list{
  background-image: url('/src/res/arow_down.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  flex: 0 0 2.5rem;
  background-color: #070707d0; 
  border-radius: .5rem .5rem 0 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(44, 44, 44, 0.521);
}
.eval_list_panel{  
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.eval_list_head{
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eval_list_avt{
  border: 1px solid rgb(161, 161, 161);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.eval_list_nick{
  margin-left: .6rem;
  color: rgb(226, 226, 226);
  font-size: 14px;
}
.eval_list_nick span{
  color: rgb(8, 90, 110);
}
.eval_list_text{
  width: 75%;
  margin: 0 auto;
  color: rgb(226, 226, 226);
  font-size: 15px;

}
.eval_location{
  margin: .5rem auto;
  font-size: 12px;
  color: #6b6b6b;
}
.eval_split{
  border-bottom: 1px solid rgb(54, 54, 54);
  margin: 2rem;
}
.eval_list_oth{
  height: 2rem;
  display: flex;
  flex-direction: row;
  width: 75%;
  margin: 0 auto;
}
.eval_list_btn{
  background-repeat: no-repeat;
  background-position: left;
  color: rgb(136, 136, 136);
  width: 4.4rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center ;
  display: flex;
  cursor: pointer;
}
.eval_list_recv{
  background-image: url('/src/res/recv.png');
}
.eval_list_start{
  background-image: url('/src/res/start_img.png');
  background-size: 35%;
}
.close_img{
  margin-left: 6rem;
  cursor: pointer;
}
.eval_recv_box{
  height: 2.5rem;
  background-color: #00000098;
  border-top: 1px solid rgb(39, 39, 39);
  display: flex;
  align-items: center;
  justify-content: center;
}
.eval_input{
  color: white;
  padding: 0 .5rem;
  width: 100%;
  height: 100%;
  background-color: #72727257;
  border: 0px;
}
.eval_recv_title{
  height: 1.5rem;
  width: 100%;
  padding: 0 .5rem;
  line-height: 1.5rem;  
  display: flex;
  flex-direction: row;  
  align-items: center;
}
.eval_recv_left
{
  color: rgb(146, 132, 7);
  width: 100%;
}
.eval_recv_close{  
  width: 5rem;
  text-align: center;
  color: rgb(146, 132, 7);
  cursor: pointer;
}
.load_more_eval{
  width: 75%;
  margin: 0 auto;
  cursor: pointer;  
  color: rgb(206, 84, 28);
}
.eval_spanel{
  width: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.change_panel{
  width: 30rem;
  height: 3rem;
  position: absolute;
  margin: 0 auto;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.change_panel div{
  background-color: #000000dc;
  width: 8rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: .3rem;
  border: 1px solid rgb(136, 136, 136);
}